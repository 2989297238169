@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Roboto:wght@400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
:root {
	--title: #000000;
	--title-rgb: 0, 0, 0;
	--text: #6b7280;
	--body: #ffffff;
	--section: #fefeff;
	--white: #ffffff;
	--base: #f2884b;
	--body-fonts: 'Raleway', sans-serif;
	--heading-fonts: 'Raleway', sans-serif;
	--inter: 'Inter', sans-serif;
	--roboto: 'Roboto', sans-serif;
	--opensans: 'Open Sans', sans-serif;
	--border: #e5e5e5;
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--title);
	font-weight: normal;
	font-size: 15px;
	line-height: 17.61px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 54px;
}
h2 {
	font-size: 45px;
}
h3 {
	font-size: 37px;
}
h4 {
	font-size: 31px;
}
h5 {
	font-size: 26px;
}
h6 {
	font-size: 22px;
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
@include breakpoint(xxl) {
	.container {
		max-width: 2350px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}

@include breakpoint(xl) {
	.container-fluid {
		max-width: 1406px;
		padding-inline: 15px;
	}
}
.dropdown {
	.btn {
		padding: 0;
		&::after {
			display: none;
		}
	}
	.dropdown-menu {
		a {
			color: var(--title);
			&:hover {
				color: var(--base);
			}
			&:active {
				color: var(--white);
				background: var(--base);
			}
		}
	}
}
.dropdown-area {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border-radius: 72px;
	border: 1px solid #fff;
	background: #fff;
	width: 117px;
	.__avatar {
		width: 56px;
		height: 56px;
		img {
			width: 100% !important;
			aspect-ratio: 1;
			border: 4px solid var(--base);
			border-radius: 50%;
			object-fit: cover;
		}
	}
}
.no-gutter {
	border: none;
	outline: none;
	box-shadow: none;
	background: transparent;
}
.dashboard-header {
	background: var(--base);
	font-family: var(--inter);
	a {
		color: var(--white);
	}
	padding: 16px 0;
	position: sticky;
	top: 0;
	z-index: 999;
	.__avatar img {
		width: 32px;
	}
	.hamburger {
		cursor: pointer;
		outline: none;
		background: transparent;
		border: none;
	}
	color: var(--default-white, #fff);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	.logo {
		max-width: 138px;
		img {
			width: 100%;
		}
	}
	.link {
		border-bottom: 2px solid transparent;
		&.active {
			border-bottom: 2px solid var(--white);
		}
	}
	@include breakpoint(max-sm) {
		padding: 16px 0;
		.logo {
			max-width: 70px;
		}
		.link {
			font-size: 10px;
		}
	}
}

.dropdown-menu {
	background: var(--section);
	li {
		a {
			color: var(--text);
		}
	}
}
.dashboard-body {
	padding-top: 29px;
	padding-bottom: 45px;
	.title {
		color: var(--gray-900, #111928);
		font-size: 52px;
		font-weight: 500;
		line-height: 150%;
		margin: 0;
	}
	@include breakpoint(max-xl) {
		padding-top: 93px;
		.title {
			font-size: 42px;
		}
	}
	@include breakpoint(max-lg) {
		padding-top: 73px;
		.title {
			font-size: 30px;
		}
	}
	.container {
		@include breakpoint(xl) {
			max-width: 1304px;
			padding-inline: 15px;
		}
	}
}
.mb-41 {
	margin-bottom: 41px;
	@include breakpoint(max-md) {
		margin-bottom: 35px;
	}
}
.export-button {
	color: var(--white, #fff);
	padding: 9px 17px;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	border-radius: 4px;
	background: #f2884b;
	border: none;
	font-family: var(--inter);
	outline: none;
	box-shadow: none;
	@include breakpoint(max-sm) {
		font-size: 14px;
		padding-inline: 14px;
		font-weight: 400;
	}
	appearance: none;
	-webkit-appearance: none;
	border-radius: 8px;
}
.__table-container {
	border-radius: 8px;
	background: #f1f1f1;

	&-inner {
		border-radius: 8px;
		overflow: hidden;
	}
}
.__table {
	margin: 0;
	* {
		border-color: #e5e7eb !important;
	}
	thead {
		tr {
			th {
				color: var(--default-gray-500, #6b7280);
				font-family: var(--roboto);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.6px;
				text-transform: capitalize;
				// background: var(--default-gray-50, #f9fafb);
				padding: 12px 24px;
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 16px 24px;
				vertical-align: middle;
			}
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
	.title-info {
		font-family: var(--inter);
		.subtitle {
			color: var(--default-gray-900, #111827);
			margin: 0;
			font-family: var(--inter);
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}
		color: var(--default-gray-500, #6b7280);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		@include breakpoint(max-md) {
			width: 220px;
		}
	}
}
.author-info {
	display: flex;
	gap: 16px;
	img {
		width: 40px;
		aspect-ratio: 1;
		object-fit: cover;
		border-radius: 50%;
	}
	min-width: 120px;
	font-family: var(--inter);
	.name {
		color: var(--default-gray-900, #111827);
		font-family: var(--inter);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin: 0;
	}
	a {
		color: var(--default-gray-500, #6b7280);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
	@include breakpoint(max-md) {
		width: 220px;
	}
}
.badge-soft-stat {
	color: var(--default-green-800, #ffffff);
	font-family: var(--inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	border-radius: 130px;
	background: var(--default-green-100, #3985d1);
	display: inline-block;
	padding-block: 0;
}
.badge-soft-guess {
	color: var(--default-green-800, #065f46);
	font-family: var(--inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	border-radius: 130px;
	background: var(--default-green-100, #d1fae5);
	display: inline-block;
	padding-block: 0;
}
.partie-vendeur-banner {
	position: relative;
	&::before {
		content: '';
		background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 10.89%,
				rgba(0, 0, 0, 0.6) 65.18%
			),
			rgb(211, 211, 211, 0) 50% / cover no-repeat;
		position: absolute;
		inset: 0;
		z-index: 1;
		opacity: 0.7;
	}
}

.mobile-screen-wrapper {
	@media (min-width: 500px) {
		background: var(--title);
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
	}
}

.wrapper {
	padding: 0;
	width: 100%;
	margin: 0 auto;
	height: 100vh;
	// overflow-y: auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	max-width: 500px;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	@media (min-width: 500px) {
		max-height: 836px;
		border-radius: 16px;
		background: var(--body);
		max-width: 390px;
		margin: auto;
		width: 390px;
	}
}
.partie-vendeur-banner {
	height: 326px;
	position: relative;
	.bg-img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&-content {
		position: relative;
		padding: 16px 16px 56px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		gap: 14px;
		align-items: flex-start;
		height: 100%;
		z-index: 1;
		.title {
			color: #fff;
			font-size: 36px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: -0.72px;
		}
	}
}
.partie-vendeur-content {
	text-align: center;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 24px 16px;
	background: var(--white);
	position: relative;
	z-index: 1;
	&::before {
		content: '';
		height: 32px;
		width: 100%;
		bottom: calc(100% - 1px);
		position: absolute;
		z-index: 1;
		left: 0;
		background: var(--white);
		border-radius: 32px 32px 0 0;
	}
	.content {
		width: 100%;
		max-width: 236px;
		margin-inline: auto;
	}
}
.__content-236 {
	width: 100%;
	max-width: 236px;
	margin-inline: auto;
}
.subtext {
	color: #000;
	text-align: center;
	font-size: 15px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.3px;
	margin-bottom: 28px;
}
.cmn-btn {
	border-radius: 4px;
	background: var(--base);
	height: 39px;
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.32px;
	color: var(--white);
	transition: all ease 0.3s;
	border: 1px solid var(--base);
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: var(--white);
		color: var(--base);
	}
}
.cmn-outline-btn {
	border-radius: 4px;
	height: 39px;
	color: var(--base);
	border: 1px solid var(--base);
	background: var(--white);
	transition: all ease 0.3s;
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.32px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: var(--base);
		color: var(--white);
	}
}
.qr-code-wrapper {
	position: absolute;
	// top: 100%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: all ease 0.4s;
	z-index: 99;
	.backdrop {
		height: 91px;
		width: 100%;
		position: relative;
		z-index: 3;
		min-height: 50px;
	}
	.qr-code-inner {
		background: var(--white);
		flex-grow: 1;
		border-radius: 30px 30px 0px 0px;
		z-index: 99;
		padding: 42px 16px 24px;
		.title {
			color: #000;
			font-size: 45px;
			font-weight: 700;
			line-height: 100%;
			letter-spacing: -0.9px;
			margin: 0;
		}
		display: flex;
		flex-grow: 1;
		flex-direction: column;
	}
	overflow-y: auto;
	&:not(.open) {
		transform: translateY(100%);
		visibility: hidden;
	}
}
.mt-70 {
	margin-top: 70px;
}
.text-xs {
	color: #000;
	font-size: 13px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.26px;
	svg {
		margin-bottom: 3px;
	}
}
.wrapper-inner {
	padding: 0 16px 24px;
}
.gap-border {
	height: 42px;
	border-bottom: 1px solid var(--base);
	margin-inline: -16px;
	opacity: 0;
}
.ajout-note-info {
	display: flex;
	flex-wrap: wrap;
	row-gap: 9px;
	color: #000;
	font-size: 15px;
	line-height: normal;
	letter-spacing: -0.3px;
	.item {
		width: 50%;
	}
}
.form--control {
	outline: none;
	border-radius: 5px;
	border: 1px solid var(--default-gray-300, #d1d5db);
	background-color: var(--default-white, #fff);
	resize: none;
	&::placeholder {
		color: var(--default-gray-500, #6b7280);
	}
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding-inline: 8px;
	height: 38px;
	&:focus {
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
		border-color: var(--base);
	}
	&:where(textarea) {
		padding: 12px 8px;
		height: 388px;
	}
	.form-select {
	}
}
.text-1 {
	color: var(--default-gray-500, #6b7280);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
.mb-39px {
	margin-bottom: 39px;
}
.mt-92px {
	margin-top: 92px;
}

.mb-36 {
	margin-bottom: 36px;
}
.subtext-2 {
	max-width: 268px;
	margin-inline: auto;
}
.form--label {
	color: var(--default-gray-700, #374151);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.28px;
	margin-bottom: 8px;
}
.form--check {
	padding: 0;
	margin: 0;
	* {
		margin: 0 !important;
	}
	display: flex;
	gap: 8px;
	.form-check-input {
		width: 18px;
		height: 18px;
		transform: translateY(5px);
		display: block;
		box-shadow: none !important;
		border: 2px solid #b3b7bd;
		&:checked {
			background-color: var(--base);
			border-color: var(--base);
		}
	}
	.form-check-label {
		width: 0;
		flex-grow: 1;
		color: var(--default-gray-700, #374151);
		font-size: 10px;
		font-weight: 500;
		line-height: 135%;
		letter-spacing: -0.2px;
	}
	.form-check-label2 {
		width: 0;
		flex-grow: 1;
		color: var(--default-gray-700, #374151);
		font-size: 14px;
		font-weight: 500;
		line-height: 135%;
		letter-spacing: -0.2px;
	}
}
.backdrop-border {
	position: relative;
	&::before {
		position: absolute;
		top: 42px;
		content: '';
		left: 0;
		width: 100%;
		border-top: 1px solid var(--base);
		opacity: 0.5;
	}
}
.subtitle-1 {
	color: #000;
	font-size: 15px;
	font-weight: 400;
	line-height: normal;
}
.mb-41 {
	margin-bottom: 41px;
}
.label-medium {
	color: var(--default-gray-700, #374151);
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.32px;
}
.__content-316 {
	max-width: 316px;
	margin-inline: auto;
	.subtitle {
		color: #000;
		font-size: 26px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: -0.52px;
	}
}
.__concours-content {
	margin-top: 5px;
	border-radius: 10px;
	border: 1px solid rgba(141, 225, 139, 0.4);
	background: rgba(141, 225, 139, 0.2);
	text-align: center;
	padding: 16px 16px 41px;
	.subtitle {
		color: #57b455;
		font-size: 26px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: -0.52px;
	}
	.subtext {
		margin: 0;
		color: #57b455;
	}
}
.__content-268 {
	max-width: 268px;
	margin-inline: auto;
}

.center {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
}

.title--small {
	font-size: 32px;
}

.MuiFormControl-root {
	border-radius: 5px;
	font-family: '';
	font-family: var(--body-fonts) !important;

	> * {
		border: none !important;
		font-family: var(--body-fonts) !important;
	}

	fieldset {
		border-color: var(--default-gray-300, #d1d5db) !important;
	}
}
.sign-out {
	width: 157px;
	height: 48px;
	flex-shrink: 0;
	border-radius: 8px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	color: white;
	background-color: none;
	background: transparent;
	transition: all ease 0.3s;

	&:hover {
		background-color: #d87c46;
	}

	@include breakpoint(max-sm) {
		height: 32px;
		width: unset;
		padding-inline: 10px;
		font-size: 10px;
		svg {
			width: 16px;
			height: 16px;
		}
	}
}
.table--bordered {
	text-align: center;
	font-family: var(--opensans);
	tr {
		td,
		th {
			vertical-align: middle;
			padding: 12px !important;
			border: none;
			font-family: var(--opensans);
		}
		th,
		td {
			&:not(:first-child) {
				border-right: 1px solid var(--border);
			}
		}
		th {
			padding-top: 0 !important;
		}
		td {
			&:not(:first-child) {
				border-bottom: 1px solid var(--border);
			}
		}
	}
	.direction {
		background: var(--base);
		font-size: 16px;
		padding: 12px;
		color: #000;
		font-family: var(--opensans);
	}
	.count {
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		color: #9c9997;
		font-family: var(--opensans);
	}
	.total {
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		color: #000000;
		font-family: var(--opensans);
	}
	.border-name {
		border: 1px solid rgba(#f2884b, 0.4) !important;
		padding: 33px 10px;
		.name {
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			margin: 0;
			font-family: var(--opensans);
		}
	}
	@include breakpoint(max-lg) {
		.count {
			font-size: 24px;
		}
		.border-name {
			padding: 20px 10px;
			.name {
				font-size: 16px;
			}
		}
	}
	@include breakpoint(max-md) {
		.count {
			font-size: 16px;
		}
		.border-name {
			padding: 10px 10px;
			.name {
				font-size: 14px;
			}
		}
	}
}
.__table {
	.subname {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
	}
	.__btn {
		white-space: pre;
		width: 100%;
		@include breakpoint(max-sm) {
			min-width: 180px;
		}
	}
}
.btn.btn-outline-danger,
.btn.btn-outline-base {
	height: 40px;
}
.btn-outline-base {
	border-radius: 8px;
	border: 1px solid var(--base) !important;
	color: var(--base);
	background: #faf7f5;
	&:hover {
		background: var(--base);
		color: #fff;
	}
}
.btn-outline-danger {
	border-radius: 8px;
	border: 1px solid #f24b4b !important;
	background: #faf7f5;
	color: #f24b4b;
	&:hover {
		background: #f24b4b;
	}
}
.user-add-form-header {
	h4 {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		@include breakpoint(max-sm) {
			font-size: 24px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 400;
		@include breakpoint(max-sm) {
			font-size: 16px;
		}
	}
}
.user-add-form {
	.cmn-btn {
		font-weight: 400;
	}
}
.mb-max-sm-0 {
	@include breakpoint(max-sm) {
		margin-bottom: 0 !important;
	}
}
.form-control-item {
	margin-bottom: 20px;
	.form-label {
		margin: 0;
		color: var(--text);
		position: relative;
		z-index: 1;
		transition: all ease 0.3s;
	}
}
.form-control-item {
	.form-control {
		font-weight: 600;
		font-size: 24px;
		border: none;
		border-radius: 0;
		padding: 0;
		height: 50px;
		box-shadow: none !important;
		outline: none !important;
		border-bottom: 1px solid var(--default-gray-300, #d1d5db);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		font-family: var(--opensans);
	}
	&:not(.active) {
		.form-label {
			transform: translateY(30px);
		}
	}
}

.eye {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 48px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 9;
}
.login-wrapper {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
	.login-left {
		width: 200px;
		flex-grow: 1;
		max-width: 816px;
		padding: 50px 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
		}
	}
	.login-right {
		flex-grow: 1;
		padding: 120px 30px;
		.login-right-form {
			max-width: 572px;
			margin: 0 auto;
		}
	}
	@include breakpoint(max-md) {
		.login-left,
		.login-right {
			width: 100%;
		}
		.login-left {
			padding-block: 70px;
			img {
				max-width: 260px;
			}
		}
		.login-right {
			padding-block: 80px 60px;
		}
	}
}
.login-form {
	.form-label {
		font-size: 20px;
	}
}
.dashboard-details-card {
	border-radius: 12px;
	background: #fff;
	box-shadow: 0px 12px 33.8px 0px rgba(0, 0, 0, 0.08);
	padding: 32px;
}
